<template>
  <div id="Aicrewards">
    <div class="aorbanner">
      <div class="content">
        <div class="bimages">
          <img src="@/assets/image/aor-desktop.gif" class="desktopimg" />
          <img src="@/assets/image/aor-mobile.gif" class="mobileimg" />
        </div>
      </div>
    </div>
    <div class="tabsection">
      <div class="content">
        <div class="title text-center">
          <h2>Account Opening Rewards</h2>
        </div>
        <div class="tabboxwpr">
          <div class="box active" data-id="box1">
            <div class="step"><span>01</span> Open an account</div>
            <h2>Enjoy Commission Free trades for all BUY trades*</h2>
            <p>*T&Cs apply.</p>
          </div>
          <div class="box" data-id="box2">
            <div class="step"><span>02</span> Deposit Any Amount​​​​</div>
            <h2>Enjoy Stock Bundle Worth MYR 180 (Equivalent to SGD50~)</h2>
            <p>*T&Cs apply.</p>
          </div>
          <div class="box" data-id="box3">
            <div class="step"><span>03</span> SaveUP For 3 Months</div>
            <h2>Enjoy 10% Booster Reward on your RSP Orders*</h2>
            <p>*T&Cs apply.</p>
          </div>
        </div>
        <div class="tabcontentwpr">
          <div class="tab" id="box1"></div>
          <div class="tab" id="box2">
            <div class="row">
              <div class="col-8">
                <div class="text">
                  <h2>Stock Bundle (Fractional Shares)</h2>
                </div>
                <div class="cards card-2">
                  <div class="card">YTL Power International Bhd<br />KLSE: YTLPOWR</div>
                  <div class="card">Sunway Bhd<br />KLSE: SUNWAY</div>
                </div>
              </div>
              <div class="col-4">
                <div class="text">
                  <h2>Worth</h2>
                </div>
                <div class="cards">
                  <div class="card">MYR 180<br />(Equivalent to SGD50~)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab" id="box3">
            <div class="title text-center">
              <h2>
                The more you invest, the more your rewards pile UP.<br />Here’s how your
                investment can pay off in more ways than one!
              </h2>
            </div>
            <div class="tabfaq">
              <div class="item active">
                <div class="title">
                  <h4>Step 1 : Access SaveUP</h4>
                  <div class="icon"></div>
                </div>
                <div class="faqcontent">
                  <p>• Click on “SaveUP” in your dashboard.</p>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <h4>Step 2 : Choose Your Stock</h4>
                  <div class="icon"></div>
                </div>
                <div class="faqcontent">
                  <p>• Click “SaveUp in a new stock.”</p>
                  <p>
                    • Search for the stock you want to invest in on a recurring basis.
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <h4>Step 3 : Set Up Your Investment</h4>
                  <div class="icon"></div>
                </div>
                <div class="faqcontent">
                  <p>• Click on “Trade” and select “SaveUP.”</p>
                  <p>• Choose your preferred currency, investment frequency, and goal.</p>
                  <p>
                    • For example, invest USD 100 into Apple monthly on the first day.
                  </p>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <h4>Step 4 : Review and Confirm</h4>
                  <div class="icon"></div>
                </div>
                <div class="faqcontent">
                  <p>• Preview your order details.</p>
                  <p>• Slide to confirm and start your SaveUP.</p>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <h4>Step 5 : Earn Your Booster</h4>
                  <div class="icon"></div>
                </div>
                <div class="faqcontent">
                  <p>
                    • After your SaveUP plan has executed for three consecutive months,
                    you’ll receive a 10% Booster based on your total invested amount.
                    (Capped at USD 50)
                  </p>
                  <p>
                    • Example: If you invest USD 100 each month for three months, your
                    total investment will be USD 300. You'll receive 10% of this amount,
                    which is USD 30, as your Booster reward.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gssection">
      <div class="content">
        <div class="row">
          <div class="Text col-lg-6 row">
            <div>
              <h2>Get Started</h2>
              <div class="dbtnwpr">
                <a
                  href="https://play.google.com/store/apps/details?id=com.moveup.apps&hl=en-SG"
                  target="_blank"
                >
                  <img src="@/assets/image/footer_img7.png" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/up-move-up-trade-up/id6468649982"
                  target="_blank"
                >
                  <img src="@/assets/image/footer_img6.png" />
                </a>
              </div>
            </div>
          </div>
          <div class="ImgBox col-lg-6">
            <img src="@/assets/image/box4_img.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="faqsection">
      <div class="content">
        <div class="title text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="faqs">
          <div class="item active">
            <div class="title">
              <h4>How do I qualify for commission-free trades?</h4>
              <div class="icon"></div>
            </div>
            <div class="faqcontent">
              <p>
                • You don’t need to redeem any cards to access commission-free trades.
                Instead, you will automatically qualify – commission for all markets will
                display as $0.00 during the BUY order preview before submitting a trade.
                Now that’s a W in our books!
              </p>
              <p>
                • Note that this applies only to "BUY" trades; "SELL" trades will still
                follow the standard pricing schedule, which can be found
                <a href="/up/Pricing">here</a>.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <h4>
                When will I receive my free stock bundle after making my first deposit?
              </h4>
              <div class="icon"></div>
            </div>
            <div class="faqcontent">
              <p>
                • You will receive the free stock bundle 30 days after the campaign ends,
                and it will be automatically credited to your account – free stuff just
                hits different, doesn’t it?
              </p>
              <p>
                • Please ensure that your account value is not zero at the end of the
                campaign, as this will disqualify you from receiving the stock bundle.
                Your account value includes both your cash balance and the market value of
                your stock holdings.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <h4>
                How can I qualify for the 10% Booster on my Regular Saving Plan (RSP)
                orders?
              </h4>
              <div class="icon"></div>
            </div>
            <div class="faqcontent">
              <p>
                • To qualify for the 10% Booster, you need to place at least one RSP order
                per calendar month for 3 consecutive months during the campaign period.
                These orders must be for stocks listed on the SaveUP page. Let’s cook!
              </p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <h4>
                If I place my first RSP order midway through the campaign, will I still
                qualify for the booster?
              </h4>
              <div class="icon"></div>
            </div>
            <div class="faqcontent">
              <p>
                • Yes, as long as you place your first RSP order within the campaign
                period, your participation will begin that month. Even if the campaign
                ends afterward, you can still secure the bag and remain eligible for
                rewards.
              </p>
              <p>
                • For example, if you place your first RSP order on the last day of
                December 2024, simply continue placing RSP orders in January and February
                to qualify for the booster.
              </p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <h4>
                When will I receive the booster reward after completing the 3-month RSP
                cycle?
              </h4>
              <div class="icon"></div>
            </div>
            <div class="faqcontent">
              <p>
                • The booster reward will be credited to your UP account within 21
                calendar days from the end of the month in which you complete the
                consecutive order requirement.
              </p>
            </div>
          </div>
        </div>
        <p>
          <i>Click here for the</i>
          <a
            href="/Terms and Conditions for UPAccount Opening Rewards Campaign.pdf"
            target="_blank"
            >Terms and Conditions</a
          >.
        </p>
      </div>
    </div>
    <div class="introducingsection">
      <div class="content">
        <div class="title text-center">
          <h2>Introducing UP</h2>
        </div>
        <div class="boxwpr">
          <div class="box">
            <img src="@/assets/image/ICON-01.png" />
            <h3>Licensed and Regulated by MAS</h3>
            <p>
              UP is an investment platform by CGS International Securities Singapore Pte.
              Ltd. (CGS SG), CGS SG is licensed and regulated by the Monetary Authority of
              Singapore (MAS), you can rest easy knowing that your investments are managed
              according to stringent regulatory standards.
            </p>
            <p>
              Capital Market Services License No.: <b><i>CMS000139</i></b>
            </p>
          </div>
          <div class="box">
            <img src="@/assets/image/ICON-02.png" />
            <h3>Clients’ Fund Security</h3>
            <p>
              UP ensures that your funds are held in segregated accounts, separate from
              the broker’s own assets. This structure adheres to regulatory standards and
              provides an added level of protection for your investments.
            </p>
          </div>
          <div class="box">
            <img src="@/assets/image/ICON-03.png" />
            <h3>Long History and Heritage in Capital Markets</h3>
            <p>
              UP is an innovative solution by CGS SG, backed by over 40 years of capital
              market expertise and heritage. Founded as GK-Goh Securities in 1979, CGS SG
              offers both stability and a forward-thinking approach to investing.
            </p>
          </div>
          <div class="box">
            <img src="@/assets/image/ICON-04.png" />
            <h3>Cutting-Edge Technology for Investors</h3>
            <p>
              UP offers award-winning research insights along with state-of-the-art
              technology to empower you in making informed investment decisions, so you
              can stay ahead in the fast-moving world of capital markets.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Accountopeningrewards.js"></script>
<script>
$(document).ready(function () {
  $(".faqs .item .title,.tabfaq .item .title").click(function () {
    $(this).parent().toggleClass("active");
  });
  $(".box").click(function () {
    var ids = $(this).attr("data-id");
    $(".tab").removeClass("show");
    $("#" + ids).addClass("show");
    $(".box").removeClass("active");
    $(this).addClass("active");
  });
});
</script>
<style scoped lang="scss">
@import "./Accountopeningrewards.css";
</style>
